
import { Options } from 'vue-class-component'
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue';
import PagePlugin from '@/components/page-plugin/page-plugin.vue'
import Utils from '@/utils'
import CustomDialog from '@/components/custom-dialog/custom-dialog.vue'
import EmpowerInfo from '@/components/empower-info/empower-info.vue'
import EmpowerInfoItem from '@/components/empower-info-item/empower-info-item.vue'

@Options({
  components: {
    PagePlugin,
    CustomDialog,
    EmpowerInfo,
    EmpowerInfoItem
  }
})
export default class empower_audit extends SuperVue {
  buttonShow(name) {
    return Utils.showBtn(name);
  }

  tableData_all_data=[]

tableData_total= 0;
tableData_search_data = {
  name: '',
  user_name: ''

}

rule_tableData_search_data = {

}

tableData_detail_dialog = {
  open: false,
  title: '详情'
}

tableData_detail_data = {
  // 2021.11.09 新增
  deadline_other: '', // 其他长期使用期限
  source_other: '', // 其他成果来源
  distribution: [] as any[], // 权属内部分配情况
  obligee: '广东工业大学', // 权利人
  rests_type: '', // 其他成果类型
  confer: '', // 之前成果转化或已有商谈情况
  college_name: '',

  name: '',
  code: '',
  empower_agreement: [] as any[],
  result_type: 0,
  agent: '',
  agent_phone: '',
  live_time: '',
  empower_way: 0,
  finish_person: [] as any[],
  college: '',
  is_level: 0,
  team_name: '',
  member: '',
  signtory: '',
  source: 0,
  naturn: 0,
  is_topic: 0,
  topic_time: '',
  detaild_list: [] as any[],
  deputy: '',
  sign_phone: '',
  application_area: '',
  target_customer: '',
  rests_state: '',
  way: [],
  rests_way: '',
  expected_price: 0,
  reserve_price: 0,
  brief: '',
  technical_maturity: 0,
  economic: '',
  society: '',
  risk: ''

}

tableData_edit_dialog = {
  open: false,
  title: '审批'
}

tableData_edit_data = {
  id: 0,
  res_empower_id: 0,
  status_: 0,
  reason: ''

}

rule_tableData_edit_data = {
  status_: [
    { required: true, message: '此项不能为空', trigger: 'blur' }
  ]

}

tableData_edit1_dialog = {
  open: false,
  title: '申请异常处理'
}

tableData_edit1_data = {
  id: 0,
  res_empower_id: 0

}

rule_tableData_edit1_data = {

}

tableData_edit2_dialog = {
  open: false,
  title: '申请异常复审'
}

tableData_edit2_data = {
  id: 0,
  res_empower_id: 0

}

rule_tableData_edit2_data = {

}

status_118 = new Map<any, string>([[1, '待审核'], [2, '通过'], [3, '不通过']])
result_type_120 = new Map<any, string>([[1, '专利'], [2, '计算机软件'], [3, '集成电路布图设计'], [4, '生物医药新品种'], [5, '技术秘密'], [6, '其他']])
current_node_123 = new Map<any, string>([[0, '草稿'], [1, '待成员审核'], [2, '审核不通过'], [3, '待指派部门审核员'], [4, '待部门审核'], [5, '待指派科研院审核员'], [6, '待科研院审核'], [7, '待指派产研院审核员'], [8, '待产研院审核'], [9, '待指派产研院公示审核员'], [10, '待公示审核'], [11, '待指派合同评审员'], [12, '待合同评审'], [13, '待指派权属办合同审批人'], [14, '待权属办合同审批'], [15, '待指派权属委合同审批人'], [16, '待权属委合同审批'], [17, '待指派权属委负责人'], [18, '待权属委负责人审批'], [19, '待指派校章专员'], [20, '待盖校章'], [21, '审核通过'], [22, '待指派权属办异常审核员'], [23, '待权属办异常审核'], [24, '待指派权属委异常复审员'], [25, '待权属委异常复审']])
status__125 = new Map<any, string>([[2, '通过'], [3, '不通过']])
empower_way_128 = new Map<any, string>([[1, '所有权'], [2, '长期使用权']])
is_level_136 = new Map<any, string>([[0, '否'], [1, '是']])
source_140 = new Map<any, string>([[1, '中央和地方财政资金项目'], [2, '企业、其他社会组织委托项目'], [3, '其他']])
naturn_141 = new Map<any, string>([[0, '否'], [1, '是']])
is_topic_142 = new Map<any, string>([[1, '是'], [0, '否']])
technical_maturity_157 = new Map<any, string>([[1, '报告级'], [2, '方案级'], [3, '功能级'], [4, '仿真级'], [5, '初样级'], [6, '正样级'], [7, '环境级'], [8, '产品级'], [9, '系统级'], [10, '销售级/应用级']])

tableData_get_all_data(offset = 0, length = 10) {
  const postData = {
    offset: offset || 0,
    length: length || 10,
    user_name: this.tableData_search_data.user_name

  }
  Api.http_empower_audittableData0(postData).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.tableData_all_data = res.data.data
      this.tableData_total = res.data.total
    }
  })
}
tableData_detail_init(row) {

  Api.http_empower_audittableDataget4({ id: row.id }).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.tableData_detail_data.name = res.data.name;
      // 2021.11.09 新增
      this.tableData_detail_data.deadline_other = res.data.deadline_other
      this.tableData_detail_data.source_other = res.data.source_other
      this.tableData_detail_data.distribution = res.data.distribution
      this.tableData_detail_data.obligee = res.data.obligee
      this.tableData_detail_data.rests_type = res.data.rests_type
      this.tableData_detail_data.confer = res.data.confer
      this.tableData_detail_data.college_name = res.data.college_name

      this.tableData_detail_data.code = res.data.code;
      this.tableData_detail_data.empower_agreement = res.data.empower_agreement;
      this.tableData_detail_data.result_type = res.data.result_type;
      this.tableData_detail_data.agent = res.data.agent;
      this.tableData_detail_data.agent_phone = res.data.agent_phone;
      this.tableData_detail_data.live_time = res.data.live_time;
      this.tableData_detail_data.empower_way = res.data.empower_way;
      this.tableData_detail_data.finish_person = res.data.finish_person;
      this.tableData_detail_data.college = res.data.college;
      this.tableData_detail_data.is_level = res.data.is_level;
      this.tableData_detail_data.team_name = res.data.team_name;
      this.tableData_detail_data.member = res.data.member;
      this.tableData_detail_data.signtory = res.data.signtory;
      this.tableData_detail_data.source = res.data.source;
      this.tableData_detail_data.naturn = res.data.naturn;
      this.tableData_detail_data.is_topic = res.data.is_topic;
      this.tableData_detail_data.topic_time = res.data.topic_time;
      this.tableData_detail_data.detaild_list = res.data.detaild_list;
      this.tableData_detail_data.deputy = res.data.deputy;
      this.tableData_detail_data.sign_phone = res.data.sign_phone;
      this.tableData_detail_data.application_area = res.data.application_area;
      this.tableData_detail_data.target_customer = res.data.target_customer;
      this.tableData_detail_data.rests_state = res.data.rests_state;
      this.tableData_detail_data.way = res.data.way;
      this.tableData_detail_data.rests_way = res.data.rests_way;
      this.tableData_detail_data.expected_price = res.data.expected_price;
      this.tableData_detail_data.reserve_price = res.data.reserve_price;
      this.tableData_detail_data.brief = res.data.brief;
      this.tableData_detail_data.technical_maturity = res.data.technical_maturity;
      this.tableData_detail_data.economic = res.data.economic;
      this.tableData_detail_data.society = res.data.society;
      this.tableData_detail_data.risk = res.data.risk;
      this.tableData_detail_dialog.open = true;
      this.setDialogName(this.tableData_detail_dialog.title)

    }
  })

}
tableData_detail_cancle() {
  this.tableData_detail_dialog.open = false;
  this.removeDialogName()
}
tableData_detail_ok() {
  this.tableData_detail_dialog.open = false;
  this.removeDialogName()
}
tableData_edit_init(row) {
  // const createTime = new Date(row.created_at).getTime() + 1000 * 60 * 60 * 24
  // const currentTime = Date.now()

  // if (row.res_empower_status === 10 && Math.ceil((currentTime - createTime) / (1000 * 60 * 60 * 24)) < row.open_time) {
  //   this.$message.error('公示期内不允许审核')
  //   return
  // }

  // console.log(Math.ceil((currentTime - createTime) / (1000 * 60 * 60 * 24)))

  Api.http_empower_audittableDataget5({ id: row.id }).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.tableData_edit_data.id = row.id;
      this.tableData_edit_data.res_empower_id = res.data.res_empower_id;
      this.tableData_edit_data.status_ = res.data.status_;
      this.tableData_edit_data.reason = res.data.reason;
      this.tableData_edit_dialog.open = true;

    }
  })

}
tableData_edit_cancle() {
  this.tableData_edit_dialog.open = false;
}
tableData_edit_ok() {
  this.$refs.ref_tableData_edit_data.validate(valid => {
    if (valid) {
      const postData = {
        id: this.tableData_edit_data.id,
        res_empower_id: this.tableData_edit_data.res_empower_id,
        status_: this.tableData_edit_data.status_,
        reason: this.tableData_edit_data.reason

      }
      Api.http_empower_audittableData5(postData).then(res => {
        if (res.code !== '200') {
          this.$message.error(res.message)
        } else {
          this.tableData_edit_dialog.open = false
          this.tableData_get_all_data(0, 10)
          this.$message.success(JSON.stringify(res.message))
        }
      })
    }
  })
}
tableData_edit1_init(row) {
  Api.http_empower_audittableDataget6({ id: row.id }).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.tableData_edit1_data.id = row.id;
      this.tableData_edit1_data.res_empower_id = res.data.res_empower_id;
      this.tableData_edit1_dialog.open = true;
    }
  })

}
tableData_edit1_cancle() {
  this.tableData_edit1_dialog.open = false;
}
tableData_edit1_ok() {
  this.$refs.ref_tableData_edit1_data.validate(valid => {
    if (valid) {
      const postData = {
        id: this.tableData_edit1_data.id,
        res_empower_id: this.tableData_edit1_data.res_empower_id

      }
      Api.http_empower_audittableData6(postData).then(res => {
        if (res.code !== '200') {
          this.$message.error(res.message)
        } else {
          this.tableData_edit1_dialog.open = false
          this.tableData_get_all_data(0, 10)
          this.$message.success(JSON.stringify(res.message))
        }
      })
    }
  })
}
tableData_edit2_init(row) {

  Api.http_empower_audittableDataget7({ id: row.id }).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.tableData_edit2_data.id = row.id;
      this.tableData_edit2_data.res_empower_id = res.data.res_empower_id;
      this.tableData_edit2_dialog.open = true;

    }
  })

}
tableData_edit2_cancle() {
  this.tableData_edit2_dialog.open = false;
}
tableData_edit2_ok() {
  this.$refs.ref_tableData_edit2_data.validate(valid => {
    if (valid) {
      const postData = {
        id: this.tableData_edit2_data.id,
        res_empower_id: this.tableData_edit2_data.res_empower_id

      }
      Api.http_empower_audittableData7(postData).then(res => {
        if (res.code !== '200') {
          this.$message.error(res.message)
        } else {
          this.tableData_edit2_dialog.open = false
          this.tableData_get_all_data(0, 10)
          this.$message.success(JSON.stringify(res.message))
        }
      })
    }
  })
}
tableData_init() {
  this.tableData_get_all_data(0, 10);

}
created() {
  this.tableData_init();

}

}
